.App {
  font-family: 'Montserrat', sans-serif;
  background-color: #F3F4F8;
}

.home {
  padding: 0;
}

.home-banner-container {
  display: flex;
  justify-content: space-between;
}

.home-container {
  background: #404252;
  height: 600px;
}

.home-banner {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: 100px;
  margin-top: 5%;
  flex: 1;
}

.home-banner-image {
  position: relative;
  float: right;
  width: 25%;
  top: -290px;
}

.home-banner-title {
  position: relative;
  width: 563px;
  height: 60px;
  /* Display 1

  Titles
  */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 78px;
  /* or 134% */

  letter-spacing: 0.01em;

  /* BACKGROUND */

  color: #F3F4F8;
  margin-bottom: 200px;
  top: -40px;
}

.home-banner-text {
  position: absolute;
  width: 555px;
  height: 96px;
  left: 0px;
  top: 92px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  /* or 96% */
  color: #FFFFFF;
}


.home-banner-button {
  margin-top: 10px;
  position: relative;
  box-sizing: border-box;
  font-family: 'Montserrat';
  color: #F3F4F8;;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 14.6885px;
  gap: 5.88px;
  position: absolute;
  width: 224px;
  height: 36.72px;
  left: 0px;
  top: 231px;
  /* POP COLOR */
  background: #2BBFAD;
  /* Secondary/Grey6 */
  border: 1px solid #404252;
  border-radius: 11.0164px;
}



.home-get-started-banner-text {
  /* position: absolute; */
  width: 555px;
  height: 96px;
  /* left: 0px;
  top: 92px; */

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  /* or 96% */


  color: #404252;
}

.logo {
  display: flex; 
  margin-left: 3%;
}

.iot2cloud {
  margin-left: 10px;
  width: 220px !important;
}

.navbar {
  display: flex;
  flex-direction: row;
  margin: 0;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 30px; 
  width: 100%;
  background: linear-gradient(180deg, #333438 33.16%, rgba(64, 66, 82, 0) 100%);
  color: #fff;
}

.logo img {
  width: 50px;
}

.links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  gap: 13px;
  
  width: 628px;
  height: 35px; 
  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.link {
  width: 104px;
  height: 22px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: center;

  color: #FFFFFF;

  text-decoration: none;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
}

.link.active {
  color: #2BBFAD;
}


.home-features {
  background: #F3F4F8;
  margin-top: 10px;
  height: 700px;
}

.home-features-banner-title {
  width: 387px;
  height: 54px;
  left: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 54px;
  text-align: center;
  letter-spacing: 0.01em;
  margin-left: 850px;
  color: #404252;
  margin-top: 80px;
  top: -80px;
  border-bottom: 3px solid #2BBFAD;
 
  
}

.home-features-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  justify-items: center; 
  align-items: center;
  height: 55%;
  width: 67%;
  margin: 0 auto; 
  grid-gap: 1.5em 0; 

}

.home-feature {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 50px; 
  width: 582px;
  height: 220px;
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;


}

.home-feature-icon {
  font-size: 36px;
  margin-top: 15px;
}

.home-feature-title {
  justify-content: center;
  width: 482px;
  height: 29px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  text-align: center;
  /* POP COLOR */
  color: #2BBFAD;
  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 0;
}

.home-feature-text {
  justify-content: center;
  width: 482px;
  height: 44px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  /* Secondary/Grey5 */

  color: #5B5D6B;


  /* Inside auto layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin-left: 65px;
}

.home-partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: 35em;
  background-color: #FFFFFF;
}

.home-partners-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 5em 14em;
}


.home-partners-banner-title {
  width: auto;
  height: 54px;
  left: 50px;
  /* Display 3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 54px; /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #404252;
  /* background-color: #F3F4F8 !important; */
  display: inline-block; /* shrink to content width */
}



.home-partners-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.home-get-started-banner-title {
  width: auto;
  height: 54px;
  left: 50px;
  /* Display 3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  line-height: 54px; /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #404252;
  /* background-color: #F3F4F8 !important; */
  display: inline-block; /* shrink to content width */
  margin-bottom: 0.2em;
}

.home-get-started-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.home-get-started-button {
  margin-top: 10px;
  position: relative;
  box-sizing: border-box;
  font-family: 'Montserrat';
  color: #F3F4F8;;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 14.6885px;
  gap: 5.88px;
  position: absolute;
  width: 224px;
  height: 36.72px;
  /* POP COLOR */
  background: #2BBFAD;
  /* Secondary/Grey6 */
  border-radius: 11.0164px;
  margin-left: 25em;
  border: none;
}

.home-get-started-content {
  margin-right: 30em !important;
  margin-bottom: 4em;
}

.home-get-started-iphone {
  margin-right: 27em !important;
  margin-top: 11.5em;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: 100px;
  background: #404252;
  color: #fff;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 13px;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #F3F4F8;
  text-decoration: none; /* Remove underline */
  margin-right: auto;
  margin-top: 30px;
  margin-left: 5em;
}

.footer-link {
  margin-right: 13px;
  color: #F3F4F8;
  text-decoration: none; /* Remove underline */
}

.home-get-started {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7em 0;
  position: relative;
  height: 30em;
  /* background: url('../Assets/Rectangle.png'); */
}

.home-get-started-content {
  max-width: 50%;
  z-index: 1;
}

.home-get-started-iphone {
  position: absolute;
  right: 0;
  top: 0;
}

.copy {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #F3F4F8;
  text-decoration: none; /* Remove underline */
  margin-top: 10px; /* Add margin-top to separate from footer-links */
  margin-left: auto;
  margin-right: 5em;
}

.contact {
  position: relative;
  width: 100%;
  padding-top: 30px;
  height: 35em;
}

.contact-info-text {
  position: absolute;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px 20px;
  gap: 10px;
  margin-left: 35em;
  margin-top: 2.5em;

  /* POP COLOR */

  background: #2BBFAD;
  box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.25);
  border-radius: 16px;

  /* Set higher z-index than contact-info-form */
  z-index: 2;
}


.contact-info-text p {
  width: 20em;
  color: #FFFFFF;
}

/* CSS for spacing between elements in each row */
.contact-info-form {
  position: relative;
  z-index: 1;
  margin-right: 30em;
  float: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 791px;
  height: 473px;
  background: #FFFFFF;
  border-radius: 20px;
}

.contact-form {
  margin-top: 12px;
  width: 35em;
}


.bottom-line {
  /* remove curvature from bottom of the input */
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #5B5D6B !important;
  margin: 10px;
  padding: 5px;
  width: 100%;
  border-radius:0px !important;
  box-sizing: border-box;
  outline: none; 
  /* TODO: Change font to AbZEE */
  font-family: 'montserrat';  
}

.border-line {
  border-top: 1px solid #5B5D6B !important;
  border-left: 1px solid #5B5D6B !important;
  border-right: 1px solid #5B5D6B !important;
  border-bottom: 1px solid #5B5D6B !important;
  margin: 10px;
  padding: 5px;
  width: 100%;
  height: 10em;
  font-family: 'montserrat';
  border-radius:0px !important;
  box-sizing: border-box;
  outline: none; 
}

.contact-row {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}


.contact-submit {
  width: 110px;
  height: 31px;
  color: #fff;
  border: none;
  background: #2BBFAD !important;
  border-radius: 9.44262px;
  margin-top: 10px;
  margin-left: 6px;
}

.location {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 7em 0;
}

.contact-container {
  margin-top: 30px !important;
}

.location-info {
  display: flex;
  flex-direction: row;
}

.location-image {
  margin-right: 20px;
}


.location-banner-title {
  width: auto;
  height: 54px;
  /* Display 3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 54px; /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #404252;
  /* background-color: #F3F4F8 !important; */
  display: inline-block; /* shrink to content width */
  margin-right: 190px;
}

.location-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.about-container {
  height: 600px;
  width: 100%;
}

.our-story {
  margin-top: 60px;
  align-items: center;
  margin-left: 160px;
}

.our-story-banner-text-container {
  display: flex;
}

.our-story-banner-text {
  width: 600px;
  height: 115px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  /* Secondary/Grey6 */
  color: #404252;
  margin-left: 10em;
}



.our-story-container {
  height: 25em;
}

.our-story-banner-title {
  width: auto;
  height: 54px;
  /* Display 3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 54px; /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #404252;
  /* background-color: #F3F4F8 !important; */
  display: inline-block; /* shrink to content width */
  margin-left: 750px;
}

.our-story-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.our-purpose-container {
  height: 390px;
  background: #404252;
}

.our-purpose {
  padding-top: 60px;
  align-items: center;
  margin-left: 160px;
}

.our-purpose-banner-text {
  width: 700px;
  height: 115px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  /* Secondary/Grey6 */
  color: #FFFFFF;
  margin-left: 25em;
}

.our-purpose-banner-title {
  width: auto;
  height: 54px;
  /* Display 3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 54px; /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #F3F4F8;
  /* background-color: #F3F4F8 !important; */
  display: inline-block; /* shrink to content width */
  margin-left: 16em;
}

.our-purpose-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.our-team-card-img-container {
  background-color: #2BBFAD;
  height: 150px;
  width: 150px;
  float: left;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.our-team-card-img {
  border-radius: 24px; /* sets the border radius to 24px */
  box-sizing: border-box; /* includes the border width and padding within the element's total width and height */
}

.our-team-card-details {
  overflow: hidden; /* clears the float and contains the name and title within the parent container */
  background-color: #FFFF;
  height: 170px;
  width: 400px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.our-team-card-name {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  /* identical to box height */
  letter-spacing: 0.01em;
  color: #000000;
 
  margin-top: 50px;
  margin-bottom: 20px;
}

.our-team-card-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #000000;
  margin-top: 5px;
}

.our-team-container {
  max-width: 1300px; 
  height: 1500px;
  margin: 0 auto;
}

.our-team {
  text-align: center;
  margin-top: 60px;
}

.our-team-banner-title {
  
  height: 54px;
  /* Display 3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 54px; /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #404252;
  /* background-color: #F3F4F8 !important; */
  display: inline-block; /* shrink to content width */
  margin-bottom: 60px;
  margin-right: 80px;

}

.our-team-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* for two columns */
  /* grid-template-columns: repeat(8, 1fr); for eight columns */
  grid-gap: 100px;
  justify-content: center;
}



.our-team-card-img {
  max-width: 100%; /* Adjust the maximum width as per your requirement */
  height: 132px; /* Ensures the image maintains its aspect ratio */
}

.iot-partnerships-banner-first-text-container {
  display: flex;
  align-items: center;
}

.iot-partnerships-banner-text-left {
  flex: 1;
  width: 520px;
  height: 120px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  /* Secondary/Grey6 */

  color: #404252;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 150px;
  margin-bottom: 80px;
}

.iot-partnerships-banner-img-right {
  margin-left: 800px;
  padding-bottom: 100px;
}

.iot-partnerships-banner-second-text-container {
  display: flex;
  align-items: center;
}

.iot-partnerships-banner-img-left {
  margin-right: 260px;
  /* change-bottom later */
  margin-left: 150px; 
  float: left;
  margin-bottom: 100px;
}

.iot-partnerships-banner-text-right {
  flex: 1;
  float: right;
  width: 512px;
  height: 96px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #404252;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 800px;
}

.partnerships-banner-title {
  height: 54px;
  /* Display 3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 54px; /* identical to box height */
  text-align: center;
  letter-spacing: 0.01em;
  color: #404252;
  /* background-color: #F3F4F8 !important; */
  display: inline-block; /* shrink to content width */
  margin-bottom: 60px;
  margin-top: 100px;
  margin-left: 45%;
}

.partnerships-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.iot-partnerships-banner-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 68px;
  letter-spacing: 0.01em;

  color: #404252;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 150px;
}

.ergonomyx-partnerships-banner-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 68px;
  letter-spacing: 0.01em;

  color: #404252;

  flex: none;
  order: 0;
  flex-grow: 0;
  margin-left: 150px;
  padding-top: 100px;
}

.ergonomyx-partnerships-banner-text-container {
  background-color: #fff;
}

.ergonomyx-partnerships-banner-text-right {
  flex: 1;
  float: right;
  width: 512px;
  height: 96px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: auto;

  color: #404252;


  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 800px;
  padding-bottom: 180px;
}

.inventu-partnerships-banner-text-container {
  height: 600px;
}

.inventu-partnerships-banner-img-right {
  margin-left: 800px;
}

.grace-partnerships-banner-text-container {
  height: 600px;
  background-color: #fff;
}

.bridging-the-gap-container {
  height: 600px;
  background-color: #404252;
  display: flex;
  flex-direction: row;
}

.bridging-the-gap-first-paragraph-text {
  width: 555px;
  height: 247px;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  /* or 123% */
  color: #FFFFFF;
}

.bridging-the-gap-container-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  padding: 0 50px;
  margin-left: 100px;

}

.bridging-the-gap-container-right {
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: right;
}

.bridging-the-gap-img {
  max-width: 100%;
  height: auto;
}

.bridging-the-gap-header { 
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 58px;
  line-height: 78px;
  /* identical to box height, or 134% */

  letter-spacing: 0.01em;
  margin-right: 15px;
  margin-bottom: 20px;

  color: #FFFFFF;
  padding: 0%;
}

.bridging-the-gap-subheader {
  /* Website text style/Headline3 */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 34px;
  letter-spacing: 0.01em;

  color: #FFFFFF;
  margin-right: 65px;
  margin-top: 0px;
}

.iot2cloud-features-banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  height: 700px;
}

.iot2cloud-features-banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.iot2cloud-features-banner-title {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 58px;
  line-height: 78px;
  /* identical to box height, or 134% */

  letter-spacing: 0.01em;

  color: #404252;

}

.iot2cloud-features-banner-left {
  width: 30%;
  text-align: left;
}

.iot2cloud-features-banner-middle {
  width: 30%;
}

.iot2cloud-features-banner-right {
  width: 30%;
  text-align: left;
}

.iot2cloud-features-banner-text {
  width: 320px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;

  /* POP COLOR */

  color: #2BBFAD;
  margin-bottom: 20px;

  /* BLACK LINE */
  border-bottom: 1px solid #000000;
  padding-bottom: 5px;
  margin-bottom: 25px;
}

.iot2cloud-features-banner-text-no-line {
  width: 320px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;

  /* POP COLOR */

  color: #2BBFAD;
  margin-bottom: 20px;
}

.iot2cloud-features-banner-img {
  width: 80%;
  height: auto;
  margin-bottom: 20px;
}

.iot2cloud-features-banner-right-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;

  color: #000000;
  margin-bottom: 0;
}

.iot2cloud-features-banner-text-black {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  
  color: #000000;
  margin-right: 25px;
}

.check-img {
  margin-right: 10px;
}

body {
  margin: 0;
}

.navbar-menu-container {
  display: none;
}

.contact-info-mobile {
  display: none;
}


/* Add your desired CSS styles here */
.terms-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f9f9f9;

}

.terms {
  width: 70%;
  height: auto;
  max-width: 1000px; /* Increased width */
  padding: 40px; /* Increased padding */
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin: 0 auto; /* Center content */
  margin-top: 200px;
  margin-bottom: 200px;
}

.terms-info {
  margin: 0 auto;
  width: 90%;
}

.terms-banner-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.terms-textbox {
  width: 100%;
  resize: vertical;
  border: 1px solid #5B5D6B;
  font-size: 14px;
  font-family: 'Montserrat';
  /* make it centered */
  margin: 0 auto;
  margin-bottom: 20px;
  resize: none;

  /* Increase spacing between textbox content and surrounding div */
  /* Remove padding from textarea and apply it to a container div */
}

.terms-textbox-container {
  padding: 10px;
}

/* Customize scrollbar */
.terms-textbox::-webkit-scrollbar {
  width: 8px;
}

.terms-textbox::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
  border-radius: 8px;
}

.terms-textbox::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 8px;
}




@media screen and (max-width: 1269px) {

  /* 
media query for footer links max width 600px */


  body {
    /* Set maximum width to 560px */
    max-width: 100%;
    /* Center the content horizontally */
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;

  }
 
  .links {
   display: none;
  }

  .navbar-menu-container {
    display: flex;
    flex-direction: right;
    align-items: right;
    justify-content: right;
    width: 100%;
    height: 100%;
  }

  .home-container {
    background: #404252;
    height: 972px;
  }

  
   .home-banner-image {
    position: relative;
    width: 270px;
    top: 150px;
    transform: translateX(-40%);
    margin: 0 auto;
    max-width: 100%;
  }
  
  

  .home-banner-title {
    position: relative;
    width: 295px;
    line-height: 54px;
    height: 60px;
    font-size: 34px;
    right: 10%;
    line-height: 78px; 
    color: #F3F4F8;
    top: -40px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  } 


  .home-banner-text {
    width: 300px;
    height: 134px;
    left: 38%;
    transform: translateX(-50%);
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
  


  .home-banner-button {
    margin-top: 70px;
    position: absolute;
    left: 38%;
    transform: translateX(-50%);
  }
  
  
   

  .home-features {
    background: #F3F4F8;
    margin-top: 10px;
    height: 1550px;
  }

  .home-features-container {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, auto);
    height: auto;
    width: 100%;
    justify-items: center; 
    align-items: center;
    margin-left: auto;
    margin-right: auto
  }
  
  .home-feature {
    height: 260px;
    width: 290px;
    padding: 30px;
  }

  .home-features-banner-title {
    width: 300px;
    height: 54px; 
    font-size: 32px;
    line-height: 54px;
    text-align: center;
    letter-spacing: 0.01em;
    margin-left: auto;
    margin-right: auto;
  } 

  .home-feature-title {
    justify-content: center;
    width: 200px;
    height: 46px;
    margin: 0;
  }

  .home-feature-text {
    justify-content: center;
    width: 251px;
    height: 80px; 
    padding-top: 20px;
    color: #5B5D6B;
    margin: auto;
  }

    
  .home-partners {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 100%;
    height: 15em;
    background-color: #FFFFFF;
  }

  .home-partners-banner-title {
    font-size: 30px;
  }

  .home-get-started {
    display: none;
  }

  .contact {
    width: 100%;
    padding-top: 30px;
    height: 55em;
  }

  .contact-info-text {
    width: 80%;
    margin-left: 7%;
    margin-right: auto;
    margin-top: 32em;
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .contact-info-form {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 40px);
    max-width: 400px;
    padding: 20px;
    height: auto;
    max-height: calc(100% - 120px);
    overflow: hidden;
    background: #FFFFFF;
    border-radius: 20px;
  }

  .contact-row {
    width: 340px;
    margin-left: 110px;
  }

  .contact-h1 {
    margin-left: calc(60% - 150px);
  }

 .location-container {
   display: none;
 }

 .about-container {
   height: 260px;
 }

 .our-story {
  margin-top: 30px;
  align-items: center;
  margin-left: 20px; /* Adjust margin for smaller screens */
}

.our-story-banner-text-container {
  display: block; /* Change to block display for smaller screens */
}

.our-story-banner-text {
  width: auto; /* Remove fixed width */
  max-width: 350px; /* Set maximum width for smaller screens */
  height: auto; /* Remove fixed height */
  font-size: 16px; /* Adjust font size for smaller screens */
  line-height: 1.5; /* Adjust line height for smaller screens */
  margin-left: auto; /* Remove left margin */
  margin-right: auto; /* Remove right margin */
}

.our-story-container {
  height: 42em; /* Remove fixed height */
}

.our-story-banner-title {
  width: auto;
  height: auto; /* Remove fixed height */
  font-size: 30px; /* Adjust font size for smaller screens */
  line-height: 36px; /* Adjust line height for smaller screens */
  text-align: center;
  margin-left: calc(45% - 70px);/* Adjust margin for smaller screens */
}

.our-story-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.our-purpose-container {
  height: 25em; /* Remove fixed height */
  background: #404252;
  margin: 0 auto;
  padding: 0 20px; /* Add some padding for smaller screens */
}

.our-purpose {
  padding-top: 30px; /* Adjust padding for smaller screens */
  align-items: center;
  margin-left: auto; /* Adjust margin for smaller screens */
  margin-right: auto; /* Adjust margin for smaller screens */
}

.our-purpose-banner-text {
  width: auto; /* Remove fixed width */
  max-width: 350px; /* Set maximum width for smaller screens */
  height: auto; /* Remove fixed height */
  font-size: 16px; /* Adjust font size for smaller screens */
  line-height: 1.5; /* Adjust line height for smaller screens */
  color: #FFFFFF;
  margin-left: auto; /* Remove left margin */
  margin-right: auto; /* Remove right margin */
}

.our-purpose-banner-title {
  font-size: 30px; /* Adjust font size for smaller screens */
  line-height: 36px; /* Adjust line height for smaller screens */
  text-align: center;
  color: #F3F4F8;
  display: inline-block; /* shrink to content width */
  margin-left: calc(45% - 70px); /* Adjust margin for smaller screens */
  align-items: center;
}


.our-purpose-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.our-team-container {
  max-width: 475px; /* Set the maximum width for smaller screens */
  height: auto; /* Remove fixed height */
  margin: 0 auto;
  padding: 0 20px; /* Add some padding for smaller screens */
  padding-bottom: 65px;
}

.our-team {
  text-align: center;
  margin-top: 30px; /* Adjust margin for smaller screens */
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
}

.our-team-card-img-container {
  background-color: #2BBFAD;
  height: 100px; /* Adjust height for smaller screens */
  width: 100px; /* Adjust width for smaller screens */
  float: inline-start; /* Remove float property */
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px; /* Add margin-bottom for spacing between cards */
}

.our-team-card-img {
  border-radius: 50%; /* Adjust border radius for circular image */
  box-sizing: border-box; /* includes the border width and padding within the element's total width and height */
  height: 80px; /* Adjust height for smaller screens */
  width: 80px; /* Adjust width for smaller screens */
}

.our-team-card-details {
  overflow: hidden; /* clears the float and contains the name and title within the parent container */
  background-color: #FFFFFF;
  height: auto; /* Remove fixed height */
  width: 200px; /* Adjust width for smaller screens */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align content horizontally */
  padding: 10px; /* Add padding for spacing within cards */
}

.our-team-card-name {
  font-size: 16px; /* Adjust font size for smaller screens */
  margin-top: 20px; /* Adjust margin for smaller screens */
  margin-bottom: 10px; /* Adjust margin for smaller screens */
}

.our-team-card-title {
  font-size: 14px; /* Adjust font size for smaller screens */
  margin-top: 5px;
  margin-bottom: 20px; /* Adjust margin for smaller screens */
}

.our-team-banner-title {
  height: auto; /* Remove fixed height */
  font-size: 30px; /* Adjust font size for smaller screens */
  line-height: 36px; /* Adjust line height for smaller screens */
  text-align: center;
  color: #404252;
  display: inline-block; /* shrink to content width */
  margin-bottom: 30px; /* Adjust margin for smaller screens */
  margin-right: 0; /* Remove right margin */
}

.our-team-banner-title span {
  border-bottom: 3px solid #2BBFAD; /* add border to span instead of title */
  padding-bottom: 5px; /* optional: add some padding between text and border */
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 100%;
  height: auto;
  background: #404252;
  color: #fff;
  text-align: center;
}

.footer-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #F3F4F8;
  text-decoration: none;
  margin-top: 20px;
  margin-bottom: 20px;
}

.footer-link {
  margin-right: 13px;
  color: #F3F4F8;
  text-decoration: none;
}


.copy {
  font-family: 'Work Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px; /* Adjust font size for smaller screens */
  line-height: 20px; /* Adjust line height for better readability */
  letter-spacing: -0.02em;
  color: #F3F4F8;
  text-align: center; /* Center align text */
  margin-bottom: 20px; /* Adjust margin for smaller screens */
}

.partnerships {
  text-align: center;
}

.partnerships-banner-title {
  height: auto;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #404252;
  margin-bottom: 30px;
  margin-top: 60px;
  margin-left: auto; /* remove the margin-left */
  margin-right: auto; /* remove the margin-right */
}

.partnerships-banner-title span {
  display: block;
  border-bottom: 3px solid #2BBFAD;
  padding-bottom: 5px;
}

.iot-partnerships-banner-text-container {
  text-align: center;
  margin-bottom: 30px;
  height: auto;
}

.iot-partnerships-banner-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  margin: auto;
  text-align: center;
  letter-spacing: 0.01em;
  color: #404252;
  margin-bottom: 30px;
  margin-top: 60px;
}

.iot-partnerships-banner-first-text-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.iot-partnerships-banner-text-left {
  flex: 1 0 100%;
  max-width: 350px;
  height: auto;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404252;
  margin: 0 10px;
}

.iot-partnerships-banner-img-right {
  max-width: 300px;
  height: auto;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
}

.iot-partnerships-banner-second-text-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.iot-partnerships-banner-img-left {
  max-width: 200px;
  height: auto;
  margin: auto;
  margin-bottom: 20px;
}

.iot-partnerships-banner-text-right {
  flex: 1 0 100%;
  max-width: 450px;
  height: auto;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #404252;
  margin: 0 10px;
}


.ergonomyx-partnerships-banner-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #404252;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.ergonomyx-partnerships-banner-text-container {
  background-color: #fff;
  padding: 20px;
  height: auto;
}



.ergonomyx-partnerships-banner-text-right {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #404252;
  text-align: center;
  margin: auto;
  max-width: 350px;
}

.inventu-partnerships-banner-img-right {
  max-width: 300px;
  height: auto;
  margin: auto;
  display: block;
  margin-top: 50px;
}

.bridging-the-gap-container {
  height: auto;
  background-color: #404252;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bridging-the-gap-container-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.bridging-the-gap-first-paragraph-text {
  width: 100%;
  max-width: 600px;
  height: auto;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

.bridging-the-gap-container-right {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.bridging-the-gap-img {
  max-width: 100%;
  height: auto;
}

.bridging-the-gap-header {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  font-size: 28px;
  line-height: 40px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  margin-top: 50px;
  margin-bottom: 50px;
  margin-right: auto;
  width: 100%;
}

.bridging-the-gap-subheader {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}

.iot2cloud-features-banner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 450px;
  margin: 0 auto;
  height: auto;
}

.iot2cloud-features-banner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.iot2cloud-features-banner-title {
  font-size: 36px;
  line-height: 48px;
}

.iot2cloud-features-banner-left,
.iot2cloud-features-banner-middle,
.iot2cloud-features-banner-right {
  width: 100%;
  text-align: center;
}

.iot2cloud-features-banner-text,
.iot2cloud-features-banner-text-no-line {
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.iot2cloud-features-banner-img {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.iot2cloud-features-banner-right-header {
  font-size: 20px;
  line-height: 24px;
  text-align: start;
}

.iot2cloud-features-banner-text-black {
  margin-right: 0;
}

.footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}
  
}


@media (min-width: 1270px) and (max-width: 1439px) {

  .body {
    width: 1400px;
    margin: 0 auto;
  }
  

  .contact-info-text {
    right: 200px !important;
  }

  .home-get-started-iphone {
    right: -300px !important;
  }

  .home-features {
    height: auto;
    margin-bottom: 50px;
  }

  .home-features-banner-title {
    margin-left: auto;
    margin-right: auto;
  }


  .home-features-container {
    width: 67%;
    grid-template-columns: none;
  }

  .our-story-container {
    height: 500px;
    margin-bottom: 50px;
  }

  .our-story-banner-title {
    margin-left: 38%;
  }

  .our-story-banner-text-container {
    width: 80%;
  }

  .our-purpose-banner-title {
    margin-left: 35%;
  }

  .our-purpose-banner-text {
    margin-left: auto;
    margin-right: auto;
  }

  .our-team-banner-title {
    margin-left: 10%;
  }

  .our-team-container {
    height: 100% !important;
    margin-bottom: 50px;
  }

  .partnerships-banner-title {
    margin-right: 20% !important; 

  }

  .iot-partnerships-banner-img-right {
    max-width: 300px;
    height: auto;
    margin: auto;
    display: block;
    margin-top: 50px;
  }

  .iot-partnerships-banner-text-right {
    margin-left: auto;
    margin-right: auto;
  }

  .ergonomyx-partnerships-banner-text-right {
    margin-left: auto;
    margin-right: auto;
  }

  .inventu-partnerships-banner-img-right {
    max-width: 300px;
    height: auto;
    margin: auto;
    display: block;
    margin-top: 50px;
  }

  .iot2cloud-features-banner-container {
    margin: 0 auto;
    height: auto;
  }
  

}

@media (min-width: 1440px) {
  /* CSS rules for screens between 1400px and 1900px */

  body {
    /* Set maximum width to 1200px */
    max-width: none;
    /* Center the content horizontally */
    margin: 0 auto;
  }

  .home-banner-image{
    top: -305px !important;
    margin-left: 0;

  }

  .iot-partnerships-banner-img-right {
    max-width: 400px;
    height: auto;
    margin: auto;
    margin-right: 150px;
    display: block;
    margin-top: 50px;
  }

  .iot-partnerships-banner-text-right {
    margin-left: auto;
    margin-right: 100px;
  }

  .ergonomyx-partnerships-banner-text-right {
    margin-left: auto;
    margin-right: 100px;
  }

  .home-features {
    height: 600px;
  }

  .home-features-banner-title {
    width: 387px;
    height: 54px;
    font-size: 36px;
    line-height: 54px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
    top: 0;
  }

  .home-features-container {
    height: 80%;
    width: 80%;
    margin: 0 auto;
    grid-gap: 1.5em 0;
  }

  .home-feature {
    width: 460px;
    height: 200px;
  }

  .home-feature-title {
    width: 350px;
    height: 29px;
    font-size: 20px;
    line-height: 29px;
  }

  .home-feature-text {
    width: 350px;
    height: 44px;
    font-size: 16px;
    line-height: 22px;
    margin-left: 0;
  }

  .home-get-started-iphone {
    left: 60%;
  }

  .contact-info-text {
    right: 17% !important;
  }

  .contact-info-form {
    margin-right: 35%;
  }

  .our-story-container {
    height: 500px;
    margin-bottom: 50px;
  }

  .our-story-banner-title {
    margin-left: 38%;
  }

  .our-story-banner-text-container {
    width: 80%;
  }

  .our-purpose-banner-text {
    margin-left: auto;
    margin-right: auto;
  }

  .our-purpose-banner-title {
    margin-left: 35%;
  }

  .inventu-partnerships-banner-img-right {
    max-width: 300px;
    height: auto;
    margin: auto;
    display: block;
    margin-top: 50px;
  }
}

@media (min-width: 1720px) {

  .contact-info-text {
    right: 20% !important;
  }

  .our-purpose-banner-text {
    margin-left: 25%;
  }
  
}

@media (min-width: 2000px) {

  .home-banner-image{
    top: -325px !important;
    margin-left: 0;
  }
  
}


















